import React, { useEffect, useState } from 'react'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import JncButton from '../../../../components/global/JncButton'
import { useSelector } from 'react-redux'
import { JncCustomSelect } from '../../../../components/global/JncCustomSelect'
import BuyConfirm from './confirmModal/BuyConfirm'
import { apiService } from '../../../../../../common/apiCallService'
import { getCurrencySymbol, removeCommas, toFixedTrunc, useJunoCommonFunctions, validateAmount } from '../../../../../helpers'
import { JncSuccessModal } from '../../../../components/modals/JncSuccessModal'
import { JncFailedModal } from '../../../../components/modals/JncFailedModal'
import { JncPendingModal } from '../../../../components/modals/JncPendingModal'

export default function BalanceCryptoBuy(props) {
    const { onClose, open } = props
    const { balancesCurrencies, balancesCrypto } = useSelector(
        (state) => state.juno
    )
    const { handleCryptoExchangeRate, getCryptoBalances, getCryptoFee, getTransactions } = useJunoCommonFunctions()
    const apiUrl = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno?.clientData)
    const [amountError, setAmountError] = useState('')
    const [exchangeRate, setExchangeRate] = useState(0)
    const [isConfirmModal, setIsConfirmModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [failedModal, setFailedModal] = useState({ open: false, msg: "" })
    const [processingModal, setProcessingModal] = useState({ open: false, msg: "" })
    const [isLoading, setIsLoading] = useState(false)
    const [exchangeLoading, setExchangeLoading] = useState(false)
    const [fee, setFee] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [quotationData, setQuotationData] = useState()
    const [transactionFee, setTransactionFee] = useState(0)
    const [formData, setFormData] = useState({
        cryptoAmount: null,
        fiatAmount: null,
        cryptoId: 'ETH',
        fiatCurrency: 'USD',
    })

    const handleClose = () => {
        onClose()
        setIsLoading(false)
        setIsConfirmModal(false)
        setAmountError('')
    }

    const handleAmountChange = (e) => {
        if (exchangeRate) {
            let cryptoAmount, fiatAmount, amountError
            const name = e.target.name
            const value = e.target.value

            if (name === 'fiatAmount') {
                const fixedRate = 1 / exchangeRate
                cryptoAmount = fixedRate && removeCommas(value) * fixedRate
                cryptoAmount = toFixedTrunc(cryptoAmount, 6)
                fiatAmount = value
                amountError = validateAmount(
                    removeCommas(fiatAmount),
                    0,
                    formData.fiatCurrency,
                    balancesCurrencies
                )
            } else if (name === 'cryptoAmount') {
                fiatAmount = exchangeRate && removeCommas(value) * exchangeRate
                const totalAmount = fiatAmount + (fiatAmount * fee);
                fiatAmount = toFixedTrunc(fiatAmount, 2)
                cryptoAmount = value
                amountError = validateAmount(
                    totalAmount,
                    0,
                    formData.fiatCurrency,
                    balancesCurrencies
                )
            }

            setFormData({ ...formData, cryptoAmount, fiatAmount })
            setAmountError(amountError)
        }
    }


    const createCryptoTransaction = () => {
        setIsLoading(true)
        const blockchain = balancesCrypto.find(crypto => crypto.currencyShortName === formData.cryptoId)?.blockchain
        const payload = {
            currencyType: 'crypto',
            type: 'Buy',
            clientId: clientData?.clientId,
            transactionEmail: localStorage.getItem('user_name'),
            transactionFee: parseFloat(removeCommas(transactionFee)).toFixed(2),
            transactionDetails: {
                quotationId: quotationData?.rfq_id,
                cryptoAmount: parseFloat(removeCommas(formData.cryptoAmount)),
                fiatAmount: parseFloat(removeCommas(formData.fiatAmount)),
                cryptoId: formData.cryptoId,
                blockchain,
                fiatCurrency: formData.fiatCurrency,
                exchangeRate,
                quotationPrice: quotationData?.price,
                fee: fee * 100,
                totalAmount: parseFloat(removeCommas(totalAmount)).toFixed(2)
            },
        }
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/create-client-transaction-crypto',
            payload,
            async (data) => {
                getCryptoBalances()
                handleClose()
                setSuccessModal(true)
                getTransactions()
            },
            (err) => {
                console.log(err)
                if (!err.includes("processing")) {
                    setFailedModal({ open: true, msg: err })
                } else {
                    setProcessingModal({ open: true, msg: err })
                }
                setIsLoading(false)
            },
        )
    }

    const handleExchangeRate = async () => {
        setExchangeLoading(true)
        const payload = {
            cryptoList: [formData.cryptoId],
            fiatAmount: 1,
            fiatCurrency: formData.fiatCurrency,
            exchangeConversionType: 'fiat-to-crypto',
        };
        const response = await handleCryptoExchangeRate(payload);
        setExchangeRate(1 / response.cryptoAmounts[formData.cryptoId]);
        setExchangeLoading(false)
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!formData.cryptoId || !open) return;

            if (formData.fiatCurrency) {
                await handleExchangeRate();
            }
            const payload = {
                crypto: formData.cryptoId,
                profile: clientData.feeProfile,
                transactionType: 'Buy',
            };
            try {
                const cryptoFee = await getCryptoFee(payload);
                setFee(!cryptoFee?.error ? cryptoFee?.data?.fee / 100 : 0);
            } catch (error) {
                console.error('Failed to fetch crypto fee:', error);
            }
        };

        fetchData();
    }, [formData.cryptoId, formData.fiatCurrency, open]);


    useEffect(() => {
        const { fiatAmount } = formData;

        if (formData && fee) {
            const parsedFiatAmount = fiatAmount && parseFloat(removeCommas(fiatAmount));
            const transactionFee = parsedFiatAmount * parseFloat(fee);
            const totalAmount = parsedFiatAmount + transactionFee;
            setTransactionFee(toFixedTrunc(transactionFee, 2));
            setTotalAmount(toFixedTrunc(totalAmount, 2));
        }
    }, [formData, fee]);

    const handleQuotationAmount = () => {
        const blockchain = balancesCrypto.find(crypto => crypto.currencyShortName === formData.cryptoId)?.blockchain
        const payload = {
            userId: clientData?.accountNumber,
            crypto: formData.cryptoId,
            blockchain: blockchain,
            cryptoAmount: formData.cryptoAmount,
            side: 'Buy',
            fiat: formData.fiatCurrency
        }
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/request-for-quotation',
            payload,
            async (res) => {
                if (!res.error) {
                    setQuotationData(res?.data)
                    setFormData({
                        ...formData,
                        fiatAmount: toFixedTrunc(formData.cryptoAmount * parseFloat(res?.data?.price), 2),
                    })
                }
            },
            (err) => {
                console.log(err)
            },
        )
    }

    const resetFormData = () => {
        setFormData({
            cryptoAmount: null,
            fiatAmount: null,
            cryptoId: 'ETH',
            fiatCurrency: 'USD',
        });
    };

    const handleValidationModal = (modalSetter, additionalActions = () => { }) => {
        modalSetter(false);
        resetFormData();
        setIsConfirmModal(false);
        additionalActions();
    };

    const handleSuccessModal = () => {
        handleValidationModal(setSuccessModal);
    };

    const handleFailedModal = () => {
        handleValidationModal(setFailedModal, () => {
            getCryptoBalances();
            getTransactions();
        });
    };

    const handleProcessModal = () => {
        handleValidationModal(setProcessingModal, () => {
            getCryptoBalances();
            getTransactions();
        });
    };


    const dialogContentComponent = () => {
        return (
            <>
                {!isConfirmModal ?
                    <>
                        <div className="mb-[30px] flex justify-between items-center max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">
                                You get:
                            </label>
                            <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                <input
                                    type="text"
                                    name='cryptoAmount'
                                    placeholder='Enter Crypto Amount'
                                    data-e2e="you-get-text-field"
                                    onChange={handleAmountChange}
                                    value={formData.cryptoAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[17px] pl-6"
                                />
                                <JncCustomSelect
                                    value={formData.cryptoId}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            cryptoId: e,
                                            cryptoAmount: '',
                                            fiatAmount: '',
                                        })
                                    }
                                    options={balancesCrypto}
                                    className="max-sm:w-[136px] !rounded-l-none"
                                />
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center w-full max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">
                                You pay:
                            </label>
                            <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                <input
                                    type="text"
                                    name='fiatAmount'
                                    placeholder='Enter Fiat Amount'
                                    data-e2e="you-pay-text-field"
                                    onChange={handleAmountChange}
                                    value={formData.fiatAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[17px] pl-6"
                                />
                                <JncCustomSelect
                                    value={formData.fiatCurrency}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            cryptoAmount: '',
                                            fiatAmount: '',
                                            fiatCurrency: e,
                                        })
                                    }
                                    options={balancesCurrencies}
                                    className="max-sm:w-[136px] !rounded-l-none"
                                />
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">
                                Rate:
                            </label>
                            <div className="text-base font-normal flex items-center gap-2">
                                {exchangeLoading && <div className="animate-spin h-[18px] w-[18px] border-gray border rounded-full !border-t !border-t-brand-black" />} <span>1 {formData.cryptoId} = {toFixedTrunc(exchangeRate, 2)} {formData.fiatCurrency}</span>
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">
                                Fee:
                            </label>
                            <span className="text-base font-normal">
                                {fee * 100}%
                            </span>
                        </div>
                        <div className=" flex justify-between items-center">
                            <label className="text-base font-normal text-start">
                                Total:
                            </label>
                            <span>{getCurrencySymbol(formData.fiatCurrency)} {totalAmount || 0} ({formData.fiatCurrency})</span>
                        </div>
                        {amountError && (
                            <div
                                className="jncAlert alert alert-danger"
                                role="alert"
                                data-e2e={amountError}
                                data-e2e-error="alert"
                            >
                                {amountError}
                            </div>
                        )}
                    </>
                    :
                    <BuyConfirm formData={formData} quotationData={quotationData} exchangeRate={exchangeRate} fee={fee} totalAmount={totalAmount} />}
            </>
        )
    }

    const handleConfirmationModal = () => {
        if (formData.fiatAmount && formData.cryptoAmount && !amountError) {
            setIsConfirmModal(true)
            handleQuotationAmount();
        }
    }
    useEffect(() => {
        let intervalId = null;
        if (isConfirmModal) {
            intervalId = setInterval(() => {
                handleQuotationAmount();
            }, 10000);
        } else {
            clearInterval(intervalId);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isConfirmModal]);

    const dialogActionsComponent = () => {
        return (
            <div className="w-100">
                {
                    !isConfirmModal ?
                        <div className="jncModal__btns justify-end">
                            <JncButton
                                isFullOnMobile={true}
                                disabled={!fee || !exchangeRate}
                                text="Continue"
                                dataE2e="continue-btn"
                                onClickCall={handleConfirmationModal}
                            />
                        </div> :
                        <div className="jncModal__btns grid-on-mobile justify-end no-border">
                            <JncButton
                                isFullOnMobile={true}
                                text="Cancel"
                                disabled={isLoading}
                                dataE2e="cancel-btn"
                                onClickCall={() => handleClose()}
                            />
                            <JncButton
                                isFullOnMobile={true}
                                text="Buy Now"
                                loading={isLoading}
                                disabled={!quotationData}
                                dataE2e="buy-now"
                                onClickCall={() => createCryptoTransaction()}
                            />
                        </div>
                }
            </div>
        )
    }
    return (
        <>
            <JunoClientActionModal
                onClose={handleClose}
                titles={{ start: 'Buy Crypto', confirm: 'Buy Crypto' }}
                backTo={{ account: () => setIsConfirmModal(false) }}
                states={{
                    start: !isConfirmModal,
                    confirm: isConfirmModal,
                }}
                open={open}
                dialogContent={dialogContentComponent()}
                dialogActions={dialogActionsComponent()}
            />
            <JncSuccessModal
                open={successModal}
                onClose={handleSuccessModal}
                amount={formData.cryptoAmount}
                currency={formData.cryptoId}
                title="Buy Confirmed!"
                subtitle="Has been deposited into your Juno X wallet."
            />
            <JncFailedModal
                open={failedModal.open}
                onClose={handleFailedModal}
                error={failedModal.msg}
                title="Buy Failed!"
            />
            <JncPendingModal
                open={processingModal.open}
                title="Pending..."
                onClose={handleProcessModal}
                error={processingModal.msg}
            />
        </>
    )
}
