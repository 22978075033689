import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import FxConfirm from '../../../../components/form/FxConfirm'
import { apiService } from '../../../../../../common/apiCallService'
import {
    getCurrencySymbol,
    clearAllValuesInObj,
    validateAmount,
    toFixedTrunc,
    getClientTransactionUrl,
    removeCommas,
    useJunoCommonFunctions,
} from '../../../../../helpers'
import _ from 'lodash'
import { setSuccessMessage } from '../../../../../../Redux/actions/junoAction'

import JncCurrenciesTypeahead from '../../../../components/global/JncCurrenciesTypeahead'
import JncCurrencyDisplay from '../../../../components/global/JncCurrencyDisplay'
import JncButton from '../../../../components/global/JncButton'

export default function BalanceFiatFx(props) {
    const { onClose, open, row, selectCurrency } = props
    const { getBalances, getTransactions } = useJunoCommonFunctions()
    const clientData = useSelector((state) => state.juno.clientData)
    const apiUrl = useSelector((state) => state.config.api_url)
    const balancesData = useSelector((state) => state.juno.balancesCurrencies)
    const [isLoading, setIsLoading] = useState(false)
    const [isFailed, setIsFailed] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [amountError, setAmountError] = useState('')
    const [exchangeLoading, setExchangeLoading] = useState(false)
    const [fxRate, setfxRate] = useState(null)
    const [failedErr, setFailedErr] = useState('')
    const [fxFee, setFxFee] = useState(false);

    const dispatch = useDispatch()

    const clientTransactionFee = useSelector(
        (state) => state.juno.clientTransactionFee
    )
    const exchangeUrl =
        (window.location.hostname === 'localhost' ? apiUrl : '') +
        '/restapi/get-fiat-exchange-rate'
    const [formData, setFormData] = useState({
        fromAmount: '',
        toAmount: '',
    })

    const clientFXFee = clientTransactionFee.find(
        (e) => e.transactionType === 'FX'
    )
    const [errorInputNames, setErrorsInputNames] = useState([])
    const [selectedCurrency, setSelectedCurrency] = useState({
        from: [],
        to: [],
    })

    useEffect(() => {
        if (row) {
            if (!row.transactionDetails) {
                setSelectedCurrency((prev) => ({
                    ...prev,
                    from: row.currencyShortName ? [row.currencyShortName] : [],
                }))
            } else {
                const fxData = row.transactionDetails[0]
                setSelectedCurrency({
                    from: [fxData.fromCurrency] || [],
                    to: [fxData.toCurrency] || [],
                })
            }
        }
    }, [row, open])

    function clearFormData() {
        setFormData(clearAllValuesInObj(formData))
        setSelectedCurrency({ from: [], to: [] })
        setAmountError('')
        setIsConfirm(false)
    }

    const onCloseHandler = () => {
        if (!isLoading) {
            onClose()
            clearFormData()
            setErrorsInputNames([])
            setfxRate(null)
            setIsFailed(false)
        }
    }

    function reset() {
        setIsLoading(false)
        setIsFailed(false)
        clearFormData()
    }

    const declineConfirm = () => {
        setIsConfirm(false)
    }

    const handleToCurrency = (val) => {
        setSelectedCurrency((prev) => ({ ...prev, to: val }))
        if (val.length > 0) {
            setErrorsInputNames((prev) =>
                prev.filter((x) => x !== 'toCurrency')
            )
        }
    }
    const handleFromCurrency = (val) => {
        setSelectedCurrency((prev) => ({ ...prev, from: val }))
        if (val.length > 0) {
            setErrorsInputNames((prev) =>
                prev.filter((x) => x !== 'fromCurrency')
            )
        }
    }

    useEffect(() => {
        if (
            selectedCurrency.from.length > 0 &&
            selectedCurrency.to.length > 0
        ) {
            setFormData({
                fromAmount: '',
                toAmount: '',
            })
            handleExchangeRate()
        }
    }, [selectedCurrency])

    const handleExchangeRate = () => {
        const toCurrencyValue = selectedCurrency.to[0]
        const fromCurrencyValue =
            selectedCurrency.from[0] || row.currencyShortName
        try {
            setExchangeLoading(true)
            apiService(
                exchangeUrl,
                {
                    fromCurrency: fromCurrencyValue,
                    toCurrency: toCurrencyValue,
                },
                (data) => {
                    if (data) {
                        const transactionFee = clientFXFee
                            ? (Number(data.fixedRate) *
                                clientFXFee.percentFee) /
                            100
                            : 0
                        const fixedRate = data.fixedRate - transactionFee
                        setFxFee(transactionFee)
                        setfxRate(fixedRate)
                        setExchangeLoading(false)
                    }
                },
                (err) => {
                    console.log(err)
                    setExchangeLoading(false)
                }
            )
        } catch (err) {
            console.log(err)
        }
    }

    const handleAmountChange = (e) => {
        if (fxRate) {
            let fromAmount, toAmount, amountError
            const name = e.target.name
            const value = e.target.value

            if (name === 'toAmount') {
                const fixedRate = 1 / fxRate
                fromAmount = fixedRate && removeCommas(value) * fixedRate
                fromAmount = toFixedTrunc(fromAmount, 2)
                toAmount = value
                amountError = validateAmount(
                    removeCommas(fromAmount),
                    0,
                    selectedCurrency.from,
                    balancesData
                )
            } else if (name === 'fromAmount') {
                toAmount = fxRate && removeCommas(value) * fxRate
                toAmount = toFixedTrunc(toAmount, 2)
                fromAmount = value
                amountError = validateAmount(
                    value,
                    0,
                    selectedCurrency.from,
                    balancesData
                )
            }
            setFormData({ fromAmount, toAmount })
            setAmountError(amountError)
        } else setErrorsInputNames((prev) => [...prev, 'toCurrency'])
    }

    function validateFields() {
        let updatedErrors = []
        if (!selectedCurrency.to[0]) {
            updatedErrors = [...updatedErrors, 'toCurrency']
        }
        if (!selectedCurrency.from[0]) {
            updatedErrors = [...updatedErrors, 'fromCurrency']
        }
        if (
            validateAmount(
                removeCommas(formData.fromAmount),
                0,
                selectedCurrency.from,
                balancesData
            )
        ) {
            updatedErrors = [...updatedErrors, 'fromAmount']
            setAmountError(
                validateAmount(
                    removeCommas(formData.fromAmount),
                    0,
                    selectedCurrency.from,
                    balancesData
                )
            )
        }
        setErrorsInputNames(updatedErrors)
        return updatedErrors
    }

    function submit() {
        setIsLoading(true)
        const balance = balancesData.find(
            (x) => x.currencyShortName === selectedCurrency.from[0]
        )
        const transactionEmail = localStorage.getItem('user_name')
        const deductedAmount = fxFee && (Number(removeCommas(formData.toAmount)) * fxFee) / fxRate;
        apiService(
            getClientTransactionUrl(apiUrl),
            {
                currencyType: 'fiat',
                type: 'FX',
                client: clientData.clientId,
                transactionEmail,
                balance: {
                    balanceAmount: row.balanceAmount || balance.balanceAmount,
                },
                transactionFee: deductedAmount,
                transactionDetails: {
                    ...formData,
                    fromCurrency: selectedCurrency.from[0],
                    toAmount:
                        formData.toAmount && parseFloat(removeCommas(formData.toAmount)).toFixed(2),
                    fromAmount:
                        formData.fromAmount &&
                        removeCommas(formData.fromAmount),
                    toCurrency: selectedCurrency.to[0],
                    fxrate: fxRate,
                    transactionFee: parseFloat(deductedAmount).toFixed(2)
                },
            },
            async (data) => {
                if (data) {
                    setIsLoading(false)
                    setIsConfirm(false)
                    getBalances()
                    getTransactions()
                    dispatch(
                        setSuccessMessage('FX has been completed successfully')
                    )
                    onCloseHandler()
                }
            },
            (err) => {
                setIsLoading(false)
                setIsConfirm(false)
                setIsFailed(true)
                setFailedErr(err)
                console.log(err)
            }
        )
    }

    const setIsConfirmHandler = () => {
        const error = validateFields()
        if (error.length === 0 && !exchangeLoading) {
            setIsConfirm(true)
        }
    }

    function DialogContentComponent() {
        return (
            <div>
                {isConfirm && (
                    <FxConfirm
                        declineConfirm={declineConfirm}
                        submit={submit}
                        fromAmount={formData.fromAmount}
                        fromCurrency={selectedCurrency.from}
                        toAmount={formData.toAmount}
                        toCurrency={selectedCurrency.to}
                        fixedRate={fxRate}
                        isLoading={isLoading}
                    />
                )}
                {row && !isConfirm && (
                    <div className="balance-fiat-fx">
                        {!selectCurrency && (
                            <JncCurrencyDisplay currency={row} />
                        )}
                        {selectCurrency && (
                            <JncCurrenciesTypeahead
                                label="From Currency"
                                selected={selectedCurrency.from}
                                onChange={handleFromCurrency}
                                hasError={errorInputNames.includes(
                                    'fromCurrency'
                                )}
                            />
                        )}
                        <JncCurrenciesTypeahead
                            label="To Currency"
                            fromCurrency={
                                row.currencyShortName ||
                                (selectedCurrency.from &&
                                    selectedCurrency.from[0])
                            }
                            selected={selectedCurrency.to}
                            onChange={handleToCurrency}
                            hasError={errorInputNames.includes('toCurrency')}
                        />
                        <div
                            className="jncModal__field mb"
                            style={{ position: 'relative' }}
                        >
                            <p className="jncModal__label">
                                Amount <span className="required">*</span>
                            </p>
                            <div className="jncModal_amount_input">
                                <div
                                    className={`jn-client-prefix-input ${amountError ? 'has-error' : ''
                                        }`}
                                >
                                    <span>
                                        {selectedCurrency.from[0]
                                            ? getCurrencySymbol(
                                                selectedCurrency.from[0]
                                            )
                                            : '?'}
                                    </span>
                                    <input
                                        data-e2e="from-amount"
                                        className="jncInput"
                                        name="fromAmount"
                                        onChange={(e) => handleAmountChange(e)}
                                        value={formData.fromAmount}
                                        type="text"
                                        placeholder="Enter Amount"
                                    />
                                </div>
                                <div className="theme-modal-field-input readOnly">
                                    {(selectedCurrency &&
                                        selectedCurrency.from[0]) ||
                                        '?'}
                                </div>
                            </div>
                        </div>
                        <div className="jncModal__field mb">
                            <p className="jncModal__label">Rate</p>
                            <div className="balance-fiat-fx-exchange">
                                1 {selectedCurrency.from[0]} =&nbsp;
                                {!exchangeLoading
                                    ? toFixedTrunc(fxRate, 4)
                                    : ''}
                                &nbsp;
                                {exchangeLoading && (
                                    <div className="data-loader"></div>
                                )}
                                &nbsp;
                                {selectedCurrency.to.length > 0
                                    ? selectedCurrency.to[0]
                                    : ''}
                            </div>
                        </div>
                        <div className="jncModal__field">
                            <p className="jncModal__label">Received amount</p>
                            <div className="jncModal_amount_input">
                                <div className="jn-client-prefix-input">
                                    <span>
                                        {selectedCurrency.to[0]
                                            ? getCurrencySymbol(
                                                selectedCurrency.to[0]
                                            )
                                            : '?'}
                                    </span>
                                    <input
                                        className="jncInput"
                                        name="toAmount"
                                        value={formData.toAmount}
                                        onChange={(e) => handleAmountChange(e)}
                                        type="text"
                                        placeholder="Enter Amount"
                                    />
                                </div>
                                <div className="theme-modal-field-input readOnly">
                                    {(selectedCurrency &&
                                        selectedCurrency.to[0]) ||
                                        '?'}
                                </div>
                            </div>
                        </div>
                        {amountError && (
                            <div
                                className="jncAlert alert alert-danger"
                                role="alert"
                                data-e2e={amountError}
                                data-e2e-error="alert"
                            >
                                {amountError}
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
    function DialogActionsComponent() {
        return (
            <div className="w-100">
                {isConfirm && (
                    <div className="jncModal__btns grid-on-mobile justify-end no-border">
                        <JncButton
                            disabled={isLoading}
                            text="Cancel"
                            isOutlinedStyle={true}
                            onClickCall={declineConfirm}
                        />
                        <JncButton
                            text="Exchange"
                            loading={isLoading}
                            onClickCall={submit}
                            dataE2e="confirm-btn"
                        />
                    </div>
                )}
                {!isConfirm && (
                    <div className="jncModal__btns justify-end">
                        <JncButton
                            isFullOnMobile={true}
                            loading={isLoading}
                            disabled={amountError}
                            onClickCall={setIsConfirmHandler}
                            dataE2e="confirm"
                            text="Exchange"
                        />
                    </div>
                )}
            </div>
        )
    }

    return (
        <JunoClientActionModal
            onClose={onCloseHandler}
            titles={{ start: 'FX', confirm: 'FX Confirmation' }}
            backTo={{ account: declineConfirm }}
            states={{
                start: !isConfirm,
                confirm: isConfirm,
            }}
            open={open}
            isLoading={isLoading}
            isFailed={isFailed}
            failedText={failedErr}
            reset={reset}
            dialogContent={DialogContentComponent()}
            dialogActions={DialogActionsComponent()}
        ></JunoClientActionModal>
    )
}
